import React from 'react';
import Layout from '../components/Layout';

import Dibujo3d from '../../static/images/solutions/linea_de_lavado/c4.png'

const Service_one = ({location}) => {
    const {state = {}} = location;
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/solutions/linea_de_lavado/4.jpg)`,
                        }}
                    >
                        <h1>Sistema de reciclaje de plásticos rígidos y películas de PE</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Antecedentes</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Zion Tech ofrece una línea integrada de reciclaje y lavado para diversos plásticos duros
                                rigidos y películas film de PE, que se utiliza principalmente para reciclar y lavar
                                plásticos desecho producidas en el consumo diario, PE, LDPE, HDPE, PO, mediante sistema
                                de clasificación, trituración y lavado completo para obtener materias primas limpias y
                                renovables, estos sirven para su reutilización en el post industrial del reciclaje de
                                plásticos misceláneos rígidos y películas.
                                <br/>
                                El diseño del proceso de esta línea de reciclaje puede procesar plásticos rígidos y
                                productos de película usadas de PE \ PP de desecho, con dos funciones integradas en un
                                sólo proceso, para maximizar la producción y utilización del equipo.
                                <br/>
                                Las materias primas que pueden ser procesadas por el sistema incluyen: productos
                                plásticos de botellas post-consumo, película de LDPE, LLDPE, HDPE, BOPP, bolsas tejidas
                                de PP, bolsas de basura y bolsas de compras.
                                <br/>
                                La capacidad de entrada del sistema (plásticos rígidos): 1500 kg / h ~ 3000 kg / h;
                                <br/>
                                La capacidad de entrada del sistema (película): 500-600 kg / h ；
                                <br/>
                                <br/>
                                Línea de lavado y reciclaje de film, apta para el reciclaje, lavado y regeneración de
                                residuos (LDPE, LLDPE, HDPE, film BOPP / bolsa tejida PP / bolsa de basura / bolsa de
                                compras) generados en la vida diaria humana.
                                <br/>
                                La capacidad de procesamiento de la línea de limpieza de reciclaje de películas de PE /
                                PP es de 500-1500 kg / h.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Característica</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                <ul>
                                    <li>
                                        Se puede utilizar en una amplia gama de aplicaciones. No solo se puede
                                        utilizar para el reciclaje y la limpieza de diversos plásticos en botellas, sino
                                        que también se puede utilizar de forma estable para el reciclaje y la limpieza
                                        de lavado de plásticos de película. La tecnología de proceso es maduro y
                                        avanzado, y es una línea de limpieza de plásticos misceláneos verdaderamente
                                        económica y aplicable;
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Se puede adoptar un control inteligente para toda la operación de la línea,
                                        sistema de control eléctrico PLC integrado, para minimizar el trabajo de
                                        operación en el sitio operativo;
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Ahorro de energía y protección del medio ambiente, equipado con sistema de
                                        circulación de agente de lavado en caliente y sistema de circulación de agua de
                                        lavado en línea;
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        El sistema es fácil de operar, con un simple aprieto de tecla para abrir el
                                        modo de operación, las piezas de desgaste y las piezas de repuesto son estables
                                        y duraderas, y el mantenimiento diario es bastante cómodo y conveniente;
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Opciones de modelo</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">

                            <img src={Dibujo3d} style={{width: "100%"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
